import { useCallback, useMemo, useState } from "react";
import { useGetCurrentUserProfile } from "@portal-frontend-ssr/auth";
import { Icon } from "@portal-frontend-ssr/ui";
import { generateImageUrl, generateAssetsId } from "@portal-frontend-ssr/blast-assets-api";
import { ProfilePopup } from "./components/ProfilePopup";

import { useBreakpoints } from "@portal-frontend-ssr/ui";
import { getConfig } from "@portal-frontend-ssr/config";

const ProfileButton = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { data: userProfile } = useGetCurrentUserProfile();
  const { isSmallDesktop } = useBreakpoints();

  const handleClick = useCallback(() => {
    if (isSmallDesktop) {
      // toggle modal on desktop
      setIsPopupOpen((current) => !current);
    } else {
      // redirect to profile page on mobile
      window.location.href = `${getConfig().SPA_SITE_URL}/profile`;
    }
  }, [isSmallDesktop]);

  const avatarSrc = useMemo(() => {
    if (!userProfile?.avatarId) return undefined;
    return generateImageUrl("avatars", generateAssetsId(userProfile.avatarId, "3d"), {
      format: "auto",
      height: "200",
    });
  }, [userProfile]);

  return (
    <div className="relative">
      <div className="flex items-center gap-3">
        <button
          onClick={handleClick}
          className="max-sd:size-8 size-9 rounded-full border border-solid border-white"
          aria-label="Profile"
        >
          {avatarSrc ? (
            <img alt="avatar" src={avatarSrc} id={userProfile?.avatarId} />
          ) : (
            <Icon icon="anonUser" className="m-auto" />
          )}
        </button>
      </div>
      <ProfilePopup
        isOpen={isPopupOpen}
        avatarSrc={avatarSrc}
        username={userProfile?.username}
        className="max-sd:hidden"
      />
    </div>
  );
};

export { ProfileButton };
