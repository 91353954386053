import { getUserWallet } from ".";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../queryKeys";

export const useUserWallet = (userId?: string) => {
  return useQuery({
    queryKey: queryKeys.wallet(userId),
    queryFn: userId ? () => getUserWallet(userId) : undefined,
    enabled: !!userId,
  });
};
