import { useContext, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { GameIcon } from "./GameIcon";
import { getConfig } from "@portal-frontend-ssr/config";
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/react";
import { DownArrow } from "./DownArrow";
import { Link, useNavigate } from "@remix-run/react";
import { isSsrPage } from "@portal-frontend-ssr/helpers";
import { GameIdContext } from "@portal-frontend-ssr/providers";
import { gameTitles } from "../shared/contants";

export const GameNav = ({ className }: { className?: string }) => {
  const { gameId, enabledGames: enabledGameIds } = useContext(GameIdContext);
  const [selectedGameId, setSelectedGameId] = useState<typeof gameId>(gameId);
  const navigate = useNavigate();

  useEffect(() => {
    setSelectedGameId(gameId);
  }, [gameId]);

  const games: ((typeof enabledGameIds)[number] | "deadlock")[] = useMemo(() => {
    const games: ((typeof enabledGameIds)[number] | "deadlock")[] = [
      ...enabledGameIds,
      "deadlock", // Deadlock is not an enabled game in this app, we just want to have a link to it
    ];

    // Order
    // 1. cs
    // 2. dota
    // 3. rocket league
    // 4. deadlock
    // ... other games

    const orderedGames = games.sort((a, b) => {
      if (a === "cs") return -1;
      if (b === "cs") return 1;
      if (a === "dota") return -1;
      if (b === "dota") return 1;
      if (a === "rl") return -1;
      if (b === "rl") return 1;
      if (a === "deadlock") return -1;
      if (b === "deadlock") return 1;

      return 0;
    });

    return orderedGames;
  }, [enabledGameIds]);

  if (games.length <= 1) {
    return null;
  }

  if (!selectedGameId) {
    return (
      <div className={classNames("max-sd:flex-col sd:shrink-0 sd:items-center flex h-full gap-4 py-2", className)}>
        {games.map((gameId) => (
          <GameLink key={gameId} gameId={gameId} />
        ))}
      </div>
    );
  }

  return (
    <Listbox
      value={selectedGameId}
      onChange={(value) => {
        setSelectedGameId(value);

        if (value === "deadlock") {
          // https://deadlock.blast.tv or https://deadlock.blast-dev.tv
          window.location.href = getConfig().DEADLOCK_BASE_URL;
          return;
        }

        const isSSRPage = isSsrPage({
          pathname: `/${value}`,
          environment: getConfig().ENVIRONMENT,
        });
        if (!isSSRPage) {
          window.location.href = `${getConfig().SPA_SITE_URL}/${value}`;
        } else {
          navigate(`/${value}`);
        }
      }}
    >
      <ListboxButton
        className={classNames(
          "bg-background-95 relative flex items-center gap-2 rounded px-5 py-3 max-md:w-full max-md:justify-start md:min-w-48",
          className,
        )}
      >
        <GameIcon gameId={selectedGameId} className="sd:size-4 block size-7 shrink-0" />
        <span className="font-style-label-l1 sd:font-style-label-l4">{gameTitles[selectedGameId]}</span>
        <DownArrow className="text-foreground-90 pointer-events-none absolute right-4 top-1/2 size-2 -translate-y-1/2" />
      </ListboxButton>
      <ListboxOptions
        anchor="bottom"
        transition
        className={classNames(
          "z-navbar bg-background-95 w-[var(--button-width)] rounded [--anchor-gap:0.25rem] focus:outline-none",
          "origin-top transition duration-150 ease-out data-[closed]:-translate-y-4 data-[closed]:opacity-0 data-[leave]:data-[closed]:opacity-0",
          "shadow-background-100/50 shadow-xl",
        )}
      >
        {games.map((gameId) => (
          <ListboxOption
            key={gameId}
            value={gameId}
            className={classNames(
              "bg-background-95 data-[focus]:bg-background-90 flex cursor-pointer items-center gap-2 border border-solid border-transparent px-5 py-3 data-[selected]:hidden",
              "transition-all",
            )}
          >
            <GameIcon gameId={gameId} className="sd:size-4 block size-5 shrink-0" />
            <span className="font-style-label-l3 sd:font-style-label-l4">{gameTitles[gameId]}</span>
          </ListboxOption>
        ))}
      </ListboxOptions>
    </Listbox>
  );
};

const GameLink = ({ gameId }: { gameId: "cs" | "dota" | "gaming" | "deadlock" | "rl" }) => {
  const externalHref = useMemo(() => {
    if (gameId === "deadlock") {
      // Open deadlock.blast.tv or deadlock.blast-dev.tv
      return getConfig().DEADLOCK_BASE_URL;
    }

    const isSSRPage = isSsrPage({
      pathname: `/${gameId}`,
      environment: getConfig().ENVIRONMENT,
    });

    if (!isSSRPage) {
      // Open in SPA site
      return `${getConfig().SPA_SITE_URL}/${gameId}`;
    }

    return null; // Open within current app
  }, [gameId]);

  const children = (
    <>
      <GameIcon gameId={gameId} className="sd:size-4 block size-7 shrink-0" />
      <span className="max-sd:font-style-label-l1 sd:font-style-label-l4 block shrink-0 whitespace-nowrap">
        {gameTitles[gameId]}
      </span>
    </>
  );

  if (externalHref) {
    return (
      <a
        key={gameId}
        href={externalHref}
        className="max-sd:py-4 sd:h-full sd:px-2 sd:hover:border-neutral flex shrink-0 items-center gap-2 rounded border border-solid border-transparent transition-all duration-150 ease-in-out"
      >
        {children}
      </a>
    );
  }

  return (
    <Link
      key={gameId}
      to={gameId}
      className="max-sd:py-4 sd:h-full sd:px-2 sd:hover:border-neutral flex shrink-0 items-center gap-2 rounded border border-solid border-transparent transition-all duration-150 ease-in-out"
    >
      {children}
    </Link>
  );
};
