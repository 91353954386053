import classNames from "classnames";
import { Transition } from "@headlessui/react";
import { useLocation } from "@remix-run/react";
import { NavbarTopLevelItem } from "@portal-frontend-ssr/sanity-api";
import { TournamentTimeline } from "@portal-frontend-ssr/blast-data-api";
import { useAuth } from "@portal-frontend-ssr/auth";
import { HomeLink } from "../HomeLink";
import { MobileNavParent } from "./components/MobileNavParent";
import { MobileNavLink } from "./components/MobileNavItem";
import { GameNav } from "../GameNav";
import { Logo } from "../Logo";
import { useContext } from "react";
import { GameIdContext } from "@portal-frontend-ssr/providers";
import { TournamentTimelineNav } from "../TournamentTimelineNav";
import { DotaSearchLink } from "../DotaSearchLink";
import { JoinDiscordMobile } from "../RocketLeagueDiscordLink/RocketLeagueDiscordLink";

export const MobileBurgerMenu = ({
  className,
  navbarItems,
  isOpen,
  onClose,
  tournamentTimeline,
}: {
  className?: string;
  navbarItems: NavbarTopLevelItem[];
  isOpen: boolean;
  onClose: () => void;
  tournamentTimeline: TournamentTimeline | null;
}) => {
  const { logout, isAuthenticated } = useAuth();
  const { gameId } = useContext(GameIdContext);
  const { pathname } = useLocation();

  return (
    <Transition
      as="div"
      show={isOpen}
      enter="transition-opacity duration-200 ease-in-out"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity delay-100 duration-200 ease-in-out"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      className={classNames("h-[calc(100%-2.75rem)] w-full backdrop-blur-sm", className)}
      onClick={() => onClose()}
    >
      <Transition
        as="div"
        show={isOpen}
        enter="transition-transform duration-200 ease-in-out"
        enterFrom="-translate-x-full"
        enterTo="translate-x-0"
        leave="transition-transform duration-200 ease-in-out"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-full"
        className={classNames(
          "bg-background-100 scrollbar-none flex h-full w-80 flex-col items-start gap-9 overflow-hidden overflow-y-auto px-7 pb-24 pt-7 transition-all duration-300 ease-in-out",
        )}
        onClick={(e) => e.stopPropagation()}
      >
        {!gameId && (
          <HomeLink>
            <Logo className="!w-8" />
          </HomeLink>
        )}
        <GameNav />
        {gameId && (
          <HomeLink
            onClick={onClose}
            className={classNames("font-style-label-l2 border-y-2 border-transparent pb-2 transition-all", {
              "border-b-primary-100": pathname === `/${gameId}`,
              "text-foreground-100": pathname !== `/${gameId}`,
            })}
          >
            Home
          </HomeLink>
        )}
        {/* TODO: live nav items */}
        {navbarItems.map((item) =>
          item._type === "navbarTournamentsItem" ? (
            <TournamentTimelineNav
              key={item._key}
              topLevelNavItem={item}
              onNavLinkClick={onClose}
              data={tournamentTimeline}
            />
          ) : item.children ? (
            <MobileNavParent key={item._key} parent={item} onNavLinkClick={onClose} />
          ) : (
            <MobileNavLink key={item._key} navbarItem={item} topLevel onClick={onClose} />
          ),
        )}
        {gameId === "rl" && <JoinDiscordMobile />}
        {gameId === "dota" && <DotaSearchLink onClick={onClose} />}
        {isAuthenticated && (
          <button className="font-style-label-l2 text-neutral mt-auto pt-4" onClick={() => void logout()}>
            Sign out
          </button>
        )}
      </Transition>
    </Transition>
  );
};
