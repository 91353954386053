import { DateTime } from "luxon";
import { ComponentProps, useMemo } from "react";
import { NavbarChildItem, NavbarTopLevelItem } from "@portal-frontend-ssr/sanity-api";
import { NavTopLevelParent } from "./NavTopLevelParent";
import { MobileNavParent } from "./MobileBurgerMenu/components/MobileNavParent";
import { TournamentTimeline } from "@portal-frontend-ssr/blast-data-api";
import { GroupedNavbarItems } from "../shared/types";

interface TournamentTimelineNavProps {
  topLevelNavItem: NavbarTopLevelItem;
  onNavLinkClick?: () => void;
  data: TournamentTimeline | null;
}

export const TournamentTimelineNav = ({ topLevelNavItem, data, onNavLinkClick }: TournamentTimelineNavProps) => {
  const baseRoute = topLevelNavItem.route;

  const children: (NavbarChildItem | GroupedNavbarItems)[] = useMemo(() => {
    const children: (NavbarChildItem | GroupedNavbarItems)[] = [
      {
        _key: "all-tournaments",
        title: "All Tournaments",
        _type: "navbarItem",
        route: baseRoute,
      },
    ];

    // Live:
    if (data?.live.length) {
      // Sort by start date descending
      const sorted = data.live.sort((a, b) => b.startDate.localeCompare(a.startDate));

      // Show all if more than one
      children.push({
        _key: "live",
        _type: "groupedNavbarItems",
        badge: "live",
        headerText: "Ongoing",
        children: sorted.map((tournament): GroupedNavbarItems["children"][0] => {
          const startDateTime = DateTime.fromISO(tournament.startDate);
          const startMonth = startDateTime.toFormat("LLL");
          const startDay = startDateTime.toFormat("d");
          const endDateTime = DateTime.fromISO(tournament.endDate);
          const endMonth = endDateTime.toFormat("LLL");
          const endDay = endDateTime.toFormat("d");
          const subText = `${startMonth} ${startDay} - ` + (startMonth === endMonth ? endDay : `${endMonth} ${endDay}`); // "Sep 25 - 29" or "Sep 25 - Oct 1"

          return {
            _key: tournament.id,
            title: tournament.name,
            _type: "navbarItem",
            route: `${baseRoute}/${tournament.id}`,
            subText: subText,
          };
        }),
      });
    }

    // Upcoming:
    if (data?.upcoming.length) {
      // Sort by start date ascending
      const sorted = data.upcoming.sort((a, b) => a.startDate.localeCompare(b.startDate));
      // if live, up to 1, otherwise up to 2 items
      const sliced = sorted.slice(0, data.live.length ? 1 : 2);

      children.push({
        _key: "upcoming",
        _type: "groupedNavbarItems",
        headerText: "Upcoming",
        children: sliced.map(
          (tournament) =>
            ({
              _key: tournament.id,
              title: tournament.name,
              _type: "navbarItem",
              route: `${baseRoute}/${tournament.id}`,
            }) satisfies GroupedNavbarItems["children"][0],
        ),
      });
    }

    // Finished:
    if (data?.past.length) {
      // Sort by start date descending
      const sorted = data.past.sort((a, b) => b.startDate.localeCompare(a.startDate));

      // if live, up to 1, otherwise up to 2 items
      const sliced = sorted.slice(0, data.live.length ? 1 : 2);

      children.push({
        _key: "finished",
        _type: "groupedNavbarItems",
        headerText: "Finished",
        children: sliced.map(
          (tournament) =>
            ({
              _key: tournament.id,
              title: tournament.name,
              _type: "navbarItem",
              route: `${baseRoute}/${tournament.id}`,
            }) satisfies NavbarChildItem,
        ),
      });
    }

    return children;
  }, [data, baseRoute]);

  const navItem: ComponentProps<typeof NavTopLevelParent>["parent"] = useMemo(
    () => ({
      _type: "navbarItem",
      _key: "tournament-timeline-nav",
      route: baseRoute,
      title: "Tournaments",
      children,
    }),
    [children, baseRoute],
  );

  return (
    <>
      <NavTopLevelParent parent={navItem} className="max-sd:hidden" />
      <MobileNavParent parent={navItem} onNavLinkClick={onNavLinkClick} className="sd:hidden" />
    </>
  );
};
