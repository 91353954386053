import { useContext, useMemo } from "react";
import { Link } from "@remix-run/react";
import classNames from "classnames";
import { GameIdContext } from "@portal-frontend-ssr/providers";
import { getConfig } from "@portal-frontend-ssr/config";
import { isSsrPage } from "@portal-frontend-ssr/helpers";

export const HomeLink = ({
  children,
  className,
  onClick,
}: {
  children?: React.ReactNode;
  className?: string;
  onClick?: () => void;
}) => {
  const { gameId } = useContext(GameIdContext);

  const { route, isSsr } = useMemo(() => {
    const route = gameId ? `/${gameId}` : "/";
    const isSsr = isSsrPage({ pathname: route, environment: getConfig().ENVIRONMENT });

    return { route, isSsr };
  }, [gameId]);

  if (!isSsr) {
    return (
      <a
        href={`${getConfig().SPA_SITE_URL}${route}`}
        title="Go to homepage"
        className={classNames("block", className)}
        onClick={onClick}
      >
        {children}
      </a>
    );
  }

  return (
    <Link to={route} title="Go to homepage" className={classNames("block", className)} onClick={onClick}>
      {children}
    </Link>
  );
};
