import { useContext, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { useLocation } from "@remix-run/react";
import { GameIdContext } from "@portal-frontend-ssr/providers";
import { TournamentTimeline } from "@portal-frontend-ssr/blast-data-api";
import { NavbarTopLevelItem } from "@portal-frontend-ssr/sanity-api";
import { Icon } from "@portal-frontend-ssr/ui";
import { useAuth, useAuthModal } from "@portal-frontend-ssr/auth";
import { HomeLink } from "./components/HomeLink";
import { Logo } from "./components/Logo";
import { NavTopLevelParent } from "./components/NavTopLevelParent";
import { GameNav } from "./components/GameNav";
import { ProfileButton } from "./components/ProfileButton";
import { PointsButton } from "./components/PointsButton";
import { MobileBurgerMenu } from "./components/MobileBurgerMenu";
import { NavTopLevelLink } from "./components/NavTopLevelLink";
import { TournamentTimelineNav } from "./components/TournamentTimelineNav";
import { DotaSearchLink } from "./components/DotaSearchLink";
import { JoinDiscordDesktop } from "./components/RocketLeagueDiscordLink/RocketLeagueDiscordLink";

export const NavBar = ({
  navbarItems,
  tournamentTimeline,
}: {
  navbarItems: NavbarTopLevelItem[];
  tournamentTimeline: TournamentTimeline | null;
}) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { gameId } = useContext(GameIdContext);
  const { openModal } = useAuthModal();
  const { pathname } = useLocation();

  const { isAuthenticated } = useAuth();

  const isNavTransparent = useMemo(() => {
    const transparentPaths = ["/cs/bounty-simulator", "/cs/news", "/rl/stats", "/cs/simulator"];

    return transparentPaths.some((path) => pathname.includes(path));
  }, [pathname]);

  const handleScroll = () => {
    const position = window.scrollY;
    const pos = (24 / 100) * position;
    const percentage = Math.min(pos, 1);

    // Set the opacity of the navbar using javascript
    const navbar = document.getElementById("transparent-navbar");

    if (!navbar) return;

    navbar.style.backgroundColor = `rgb(31 12 25 / ${percentage})`;
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav
      id={isNavTransparent ? "transparent-navbar" : undefined}
      className={classNames(
        "z-navbar max-sd:h-11 sd:px-5 transition-height fixed top-0 h-[3.75rem] w-screen px-4 md:px-7",
        {
          "bg-background-100": !isNavTransparent,
        },
      )}
    >
      <div className="max-sd:justify-between relative flex h-full flex-nowrap items-center gap-5">
        <button
          aria-label={isMobileMenuOpen ? "Close Menu" : "Open Menu"}
          className="sd:hidden"
          onClick={() => setIsMobileMenuOpen((current) => !current)}
        >
          <Icon icon={isMobileMenuOpen ? "closeCircled" : "burgerMenu"} className="size-6" />
        </button>
        <HomeLink
          onClick={() => setIsMobileMenuOpen(false)}
          className={classNames(
            "max-sd:absolute max-sd:left-1/2 max-sd:top-1/2 max-sd:max-w-6 max-sd:-translate-x-1/2 max-sd:-translate-y-1/2 group box-content shrink-0 border-b-2 p-3 transition-all",
            {
              "opacity-0": isMobileMenuOpen,
              "opacity-100": !isMobileMenuOpen,
              "border-primary-100 max-sd:border-transparent": gameId && pathname === `/${gameId}`,
              "border-transparent": !gameId || pathname !== `/${gameId}`,
            },
          )}
        >
          <Logo className="h-4 transition-all group-hover:scale-125" />
        </HomeLink>
        <GameNav className="max-sd:hidden" />
        {/* TODO: live nav items */}
        <div
          className={classNames(
            "max-sd:hidden flex size-full flex-nowrap items-center gap-5 transition-opacity delay-200 duration-300",
          )}
        >
          {navbarItems?.map((item) =>
            item._type === "navbarTournamentsItem" ? (
              <TournamentTimelineNav key={item._key} topLevelNavItem={item} data={tournamentTimeline} />
            ) : item.children ? (
              <NavTopLevelParent key={item._key} parent={item} />
            ) : (
              <NavTopLevelLink key={item._key} navbarItem={item} />
            ),
          )}
          {gameId === "rl" && <JoinDiscordDesktop />}
        </div>
        {gameId === "dota" && <DotaSearchLink className="max-sd:hidden" />}
        {isAuthenticated ? (
          <div className="ml-auto flex items-center gap-2">
            <PointsButton />
            <ProfileButton />
          </div>
        ) : (
          <>
            <div
              className={classNames("ml-auto flex flex-nowrap items-center gap-1", {
                "max-sd:hidden": !isMobileMenuOpen,
              })}
            >
              <button
                onClick={() => openModal("login")}
                className="text-foreground-100 hover:text-foreground-95 font-style-label-l3 whitespace-nowrap p-3 transition-all"
              >
                Log in
              </button>
              <button onClick={() => openModal("register")} className="button whitespace-nowrap px-3">
                Join The Community
              </button>
            </div>
            {!isMobileMenuOpen && (
              <button
                className="sd:hidden size-8 rounded-full border border-solid border-white"
                onClick={() => openModal("register")}
                aria-label="Join The Community"
              >
                <Icon icon="anonUser" className="m-auto" />
              </button>
            )}
          </>
        )}
      </div>
      <MobileBurgerMenu
        navbarItems={navbarItems ?? []}
        tournamentTimeline={tournamentTimeline}
        isOpen={isMobileMenuOpen}
        onClose={() => setIsMobileMenuOpen(false)}
        className="sd:hidden fixed left-0 top-11"
      />
    </nav>
  );
};
