import { Icon } from "@portal-frontend-ssr/ui";
import { useGetCurrentUserWallet } from "@portal-frontend-ssr/auth";
import { getConfig } from "@portal-frontend-ssr/config";

export const PointsButton = () => {
  const { data: wallet, isLoading } = useGetCurrentUserWallet();

  return (
    <a
      href={`${getConfig().SPA_SITE_URL}/profile/redeem`}
      className="bg-background-90 hover:bg-background-85 flex items-center justify-between gap-2 rounded-full px-2 py-1 transition-all duration-200 ease-in-out"
    >
      <Icon icon="blastCoin" className="text-primary-100" />
      {isLoading ? (
        <span className="bg-background-80 block h-4 w-12 animate-pulse rounded-full" />
      ) : (
        <span className="font-style-label-l2 text-white">{wallet?.amount.toLocaleString()}</span>
      )}
    </a>
  );
};
