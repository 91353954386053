import discordClyde from "./assets/discord-clyde.svg";

export const JoinDiscordDesktop = () => (
  <a
    href="https://discord.gg/GMAWpkuwJe"
    target="_blank"
    className="rounded-small group relative flex h-10 h-fit w-10 cursor-pointer gap-2 bg-[#5865F2] p-3 transition-all hover:w-40 hover:bg-[#5865F2]/80"
  >
    <img src={discordClyde} className="absolute left-3 w-4" />
    <span className="font-style-label-l4 ml-6 whitespace-nowrap opacity-0 group-hover:opacity-100 group-hover:transition-opacity group-hover:delay-100 group-hover:duration-200">
      Join discord
    </span>
  </a>
);

export const JoinDiscordMobile = () => (
  <a
    href="https://discord.gg/GMAWpkuwJe"
    target="_blank"
    className="rounded-small flex h-8 w-full items-center gap-2 bg-[#5865F2] px-4 transition-all hover:bg-[#5865F2]/80"
  >
    <img src={discordClyde} className="w-4" />
    <span className="font-style-label-l4 whitespace-nowrap">Join discord</span>
  </a>
);
