import { useLocation } from "@remix-run/react";
import classNames from "classnames";
import { NavbarTopLevelItem } from "@portal-frontend-ssr/sanity-api";
import { NavLink } from "../../NavLink";
import { ExternalArrow } from "../../ExternalArrow";

export const MobileNavLink = ({
  navbarItem,
  topLevel = false,
  className,
  onClick,
}: {
  topLevel?: boolean;
  navbarItem: NavbarTopLevelItem;
  className?: string;
  onClick?: () => void;
}) => {
  const location = useLocation();

  if (navbarItem._type === "navbarTournamentsItem") {
    // Handled separately in TournamentTimelineNav
    return null;
  }

  return (
    <div
      className={classNames(
        "font-style-label-l2 flex w-fit items-center whitespace-nowrap border-y-2 border-transparent pb-2 text-center",
        {
          "text-primary-100": navbarItem.route === location.pathname && !topLevel,
          "border-b-primary-100": navbarItem.route === location.pathname && topLevel,
        },
        className,
      )}
    >
      <NavLink navbarItem={navbarItem} onClick={onClick}>
        {navbarItem.title}
        {navbarItem.externalLink && <ExternalArrow className="ml-2" />}
      </NavLink>
    </div>
  );
};
