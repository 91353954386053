import classNames from "classnames";
import bgPng from "./assets/background.png";
import { useAvatarModal } from "@portal-frontend-ssr/auth";
import { useAuth } from "@portal-frontend-ssr/auth";
import { getConfig } from "@portal-frontend-ssr/config";

export const ProfilePopup = ({
  isOpen,
  avatarSrc,
  username,
  className,
}: {
  isOpen: boolean;
  avatarSrc?: string;
  username?: string;
  className?: string;
}) => {
  const { logout } = useAuth();
  const { openModal: openAvatarModal } = useAvatarModal();

  if (!isOpen) {
    return null;
  }

  return (
    <div
      style={{ "--image-url": `url(${bgPng})` } as React.CSSProperties}
      className={classNames(
        `z-navbar absolute right-0 top-12 flex w-80 flex-col items-center bg-[image:var(--image-url)] bg-cover bg-center`,
        className,
      )}
    >
      <img alt="background" src={avatarSrc} className="m-4 size-20 rounded-full bg-white/10" />
      <div className="font-style-heading-h2 !normal-case">{username ?? "?"}</div>
      <div className="bg-background-90 mt-4 w-full px-3">
        <a
          href={`${getConfig().SPA_SITE_URL}/profile`}
          className="font-style-label-l3 hover:text-foreground-95 block w-full py-3 text-left transition-all duration-200 ease-in-out"
        >
          Go to Profile
        </a>
        <div className="bg-background-80 mx-auto h-px" />
        <button
          onClick={openAvatarModal}
          className="font-style-label-l3 hover:text-foreground-95 block w-full py-3 text-left transition-all duration-200 ease-in-out"
        >
          Change avatar
        </button>
        <div className="bg-background-80 mx-auto h-px" />
        <button
          onClick={() => void logout()}
          className="font-style-label-l3 hover:text-foreground-95 block w-full py-3 text-left transition-all duration-200 ease-in-out"
        >
          Sign out
        </button>
      </div>
    </div>
  );
};
